//settings
$fa-font-path: "../font";

// Colors //
$black: #1B1B1B;
$white: #fff;
$primary-color: #BE8C3D;
$gray: #656565;

//Fonts
$primary-font: 'Druk', sans-serif;
$primary-font-alt: 'Druk Text', sans-serif;
$secondary-font: 'Slate Std', sans-serif;

@font-face {
    font-family: 'Slate Std';
    src: url('../font/SlateStd-Book.woff2') format('woff2'),
        url('../font/SlateStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Druk Text';
    src: url('../font/DrukText-Super.woff2') format('woff2'),
        url('../font/DrukText-Super.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Druk Text Wide';
    src: url('../font/DrukTextWide-Super.woff2') format('woff2'),
        url('../font/DrukTextWide-Super.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Druk Wide Super';
    src: url('../font/Druk-WideSuper.woff2') format('woff2'),
        url('../font/Druk-WideSuper.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Druk';
    src: url('../font/Druk-Heavy.woff2') format('woff2'),
        url('../font/Druk-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Druk Super';
    src: url('../font/Druk-Super.woff2') format('woff2'),
        url('../font/Druk-Super.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Druk';
    src: url('../font/Druk-Medium.woff2') format('woff2'),
        url('../font/Druk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Druk';
    src: url('../font/Druk-MediumItalic.woff2') format('woff2'),
        url('../font/Druk-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Druk XCondensed';
    src: url('../font/DrukXCondensed-Super.woff2') format('woff2'),
        url('../font/DrukXCondensed-Super.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Druk';
    src: url('../font/Druk-Bold.woff2') format('woff2'),
        url('../font/Druk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Druk Text';
    src: url('../font/DrukText-Medium.woff2') format('woff2'),
        url('../font/DrukText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@import "variables";
// includes 
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $black;
    color: $white;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    transition: .3s all;
    @include hover{
        color: $primary-color;
    }
}

.container{
    max-width: rem(1600);
}



h2{
    font-size: rem(115);
    font-weight: 800;
    line-height: .8;
    margin: 0;
    text-transform: uppercase;
    @media screen and (max-width: 991px){
        font-size: rem(70);
    }
}

#header{
    #nav{
        padding: rem(10);
        width: 100%;
        background-color: $black;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        right: 0;
        ul{
            width: rem(740);
            max-width: 100%;
            padding: 0;
            margin: 0 0 0 auto;
            list-style: none;
            display: flex;
            justify-content: space-around;
        }
        li{
            display: inline-block;
            font-size: rem(35);
            text-transform: uppercase;
        }
        a{
            text-decoration: none;
        }
    }
    .wrap{
        display: flex;
        align-items: flex-end;
        .col{
            flex: 1;
            &:first-child{
                max-width: 90vh; 
            }
            &:last-child{
                padding-top: rem(60);
            }
        }
        
        @media screen and (max-width: 991px){
            flex-direction: column;
            align-items: center;
            .col{
                flex: auto;
                max-width: 100%;
                
                &:last-child{
                    padding-top: 0;
                }
            }
        }
    }

    .presave-btns{
        text-align: center;
        margin-top: rem(30);
        a{
            display: inline-block;
            text-decoration: none;
            margin: rem(10);
            font-size: rem(24);
            font-family: $primary-font-alt;
            color: $white;
            background-color: $black;
            padding: rem(8) rem(15) rem(5);
            text-transform: uppercase;
            border: 2px solid $white;
            @include hover{
                background-color: $white;
                color: $black;
            }
            @media screen and (max-width: 991px){
                width: rem(270);
                display: block;
                margin: rem(10) auto;
                font-size: rem(22);
                padding: rem(5);
            }
        }
    }
    .note{
        font-family: $secondary-font;
        font-size: rem(10);
        width: rem(500);
        max-width: 100%;
        margin: 0 auto;
        padding: rem(10) 0 rem(30);
        
        text-transform: uppercase;
        text-align: center;
    }
}

#tour{
    background-color: $primary-color;
    color: $black;
    display: flex;
    
    @media screen and (max-width: 991px){
        display: block;
    }
    h2{
        padding: rem(30);
        text-align: right;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        
        @media screen and (max-width: 991px){
            writing-mode: horizontal-tb;
            transform: none;
            padding: rem(20);
        }
    }
    #shows{
        border-left: 2px solid black;
        flex: 1;
        
        @media screen and (max-width: 991px){
            border-left: none;
            border-top: 2px solid black;
        }
        .event{
            padding: rem(30);
            display: flex;
            border-bottom: 2px solid $black;
            text-transform: uppercase;
            align-items: center;
            @media screen and (max-width: 991px){
                padding: rem(10);
            }
            >div{
                flex: .5;
                &.event-date{
                    font-size: rem(52);
                    font-weight: bold;
                    
                    @media screen and (max-width: 991px){
                        font-size: rem(24)
                    }
                }
                &.event-location{
                    flex: 1;
                    font-size: rem(40);
                    font-family: $primary-font-alt;
                    font-weight: 500;
                    @media screen and (max-width: 991px){
                        font-size: rem(22);
                        flex: 1.5;
                    }
                    span{
                        display: block;
                        font-size: rem(25);
                        font-family: $secondary-font;
                        font-weight: 300;
                        @media screen and (max-width: 991px){
                            font-size: rem(15)
                        }
                    }
                }
                &.event-links{
                    a{
                        display: inline-block;
                        text-decoration: none;
                        font-size: rem(40);
                        font-family: $primary-font-alt;
                        color: $primary-color;
                        background-color: $black;
                        padding: rem(8) rem(25) rem(5);
                        border: 2px solid $black;
                        @include hover{
                            background-color: $primary-color;
                            color: $black;
                        }
                        @media screen and (max-width: 991px){
                            font-size: rem(22);
                            padding: rem(5);
                        }
                    }
                }
            }

            &.event-more{
                display: block;
                text-align: center;
                font-size: rem(52);
                font-weight: bold;
                text-decoration: none;
                @media screen and (max-width: 991px){
                    font-size: rem(24)
                }
                @include hover{
                    color: $primary-color;
                    background-color: $black;
                }
            }

            &:nth-child(n+6){
                &.event-more{
                    display: none;
                }
            }
            
        }
        &:not(.seeall) .event:nth-child(n+6){
            display: none;
            &.event-more{
                display: block;
            }
        }
    }
}

#videos{
    padding-bottom: rem(50);
    h2{
        text-align: right;
        padding: rem(30) 0;
        
        @media screen and (max-width: 991px){
            text-align: left;
        }
    }
    .info{
        text-align: center;
        font-size: rem(25);
        font-family: $secondary-font;
        text-transform: uppercase;
        @media screen and (max-width: 991px){
            font-size: rem(15)
        }
        p{
            margin: .5em 0 0;
        }
        a{
            display: none;
        }
    }
}

#footer{
    display: flex;
    @media screen and (max-width: 991px){
        background-color: $primary-color;
        display: block;
        .col:first-child{
            margin-left: auto;
        }
    }
    .col{
        flex: 1;
        &:first-child{
            flex-basis: rem(500);
            max-width: rem(500);
            background-color: $primary-color;
            color: $black;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            h2{
                padding: rem(20);
                border-bottom: 4px solid $black;
                
                @media screen and (max-width: 991px){
                    text-align: right;
                    border: none;
                }
            }
            .social-wrap{
                display: flex;
                padding: rem(20) rem(10);
                justify-content: space-between;
                align-items: center;
                
                @media screen and (max-width: 991px){
                    flex-direction: column;
                    padding: 0 rem(10);
                }
                .social{
                    @media screen and (max-width: 991px){
                        order: 2;
                    }
                    padding: 0;
                    margin: 0;
                    font-size: rem(18);
                    li{
                        margin: .5em;
                        display: inline-block;
                    }
                }

                form{
                    
                    @media screen and (max-width: 991px){
                        order: 1;
                    }
                    input{
                        border: none;
                        background: none;
                        border-radius: 0;
                        border-bottom: 1px solid $black;
                        text-align: center;
                        font-family: $secondary-font;
                        font-size: rem(10);
                        text-transform: uppercase;
                        padding: rem(2) rem(5);
                        &::placeholder{
                            color: $black;
                        }
                    }

                    button{
                        border:1px solid $black;
                        border-radius: 0;
                        background: none;
                        font-family: $secondary-font;
                        font-size: rem(10);
                        padding: rem(2) rem(5);
                        text-transform: uppercase;
                        @include hover{
                            background-color: $black;
                            color: $primary-color;
                        }
                    }
                }
            }
        }
        
        &:last-child{
            padding: rem(30);
            flex: 1;
        }
    }
    .copyright{
        padding: rem(10);
        font-family: $secondary-font;
        font-size: rem(10);
        margin: 0;
        text-transform: uppercase;
        text-align: right;
        width: rem(600);
        max-width: 100%;
        margin-left: auto;
        @media screen and (max-width: 991px){
            color: $black;
            background-color: $primary-color;
            text-align: center;
        }
    }
}

#feed, #feed-m {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
      flex-basis: 33%;
      padding: 0 7px;
      margin-bottom: 14px;

      > a {
        position: relative;
        display: block;
        background: #000;
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;
        opacity: 1;
        background-size: cover;
        background-position: center center;

        > span {
          background-color: rgba(0, 0, 0, 0.8);
          bottom: 0;
          color: $white;
          display: block;
          font-size: 15px;
          height: 100%;
          left: 0;
          letter-spacing: 0.075em;
          opacity: 0;
          overflow: hidden;
          padding: 10px;
          position: absolute;
          right: 0;
          text-transform: uppercase;
          top: 0;
          transition: opacity 0.3s;
          width: 100%;
          word-wrap: break-word;
        }

        &:hover, &:focus {
          > span {
            opacity: 1;
          }
        }
      }
    }
}

@media screen and (max-width: 991px){
    .desktop-only{
        display: none !important;
    }
}

@media screen and (min-width: 992px){
    .mobile-only{
        display: none !important;
    }
}


.popup{
    background-color: $black;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
    color: $primary-color;
    background-size: cover;
    margin: rem(20) auto;
    padding: rem(30);
    width: 100%;
    max-width: rem(400);
    font-family: $secondary-font;
    font-size: rem(20);
    position: relative;
    text-align: center;
    .mfp-close{
        color: $primary-color;
    }
	p{
		color: $primary-color;
        
	}
    #apple_music_form{
        p{
            margin-bottom: rem(60);
        }
		color: $primary-color;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		text-transform: uppercase;
        display: inline-block;
        vertical-align: middle;
        font-size: rem(15);
        letter-spacing: .05em;
        @media screen and (max-width: 520px){
            margin: rem(25) auto 0;
            display: block;
        }

        .wrap{
            display: flex;
            justify-content: center;
            input, button{
                margin: rem(6);
                padding: rem(10) rem(12) rem(6);
                font-size: rem(11);
                font-family: $secondary-font;
                background: none;
                color: $primary-color;
                &::placeholder{
                    color: $primary-color;
                }
                border: 2px solid $primary-color;
                border-radius: rem(30);
            }
        }
    }
}